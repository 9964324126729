<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Code"
              v-model="filter.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Scope Category</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Scope Category"
              v-model="filter.name"
            ></b-form-input>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button
            class="btn btn-primary btn-md"
            @click="getScopeCategory(true)"
          >
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom card-stretch-fourth p-5 gutter-b">
      <div class="d-flex my-3 justify-content-start">
        <button
          class="btn btn-success btn-md"
          @click="showInputModal(null, 'Form Add Scope Category')"
        >
          <i class="flaticon-add"></i> Add Scope Category
        </button>
      </div>

      <div class="card-body p-0">
        <complete-table :loading="loading" :data="filteredScopeCategories">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Code</th>
              <th class="pl-7">Scope Category</th>
              <th class="pl-7 text-center">Action</th>
            </tr>
          </template>

          <template #defaultBody="{item: scopeCategory,i}">
            <!-- <template v-for="(scopeCategory, i) in filteredScopeCategories"> -->
              <tr v-bind:key="i" class="border-0">
                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ scopeCategory.code }}
                  </span>
                </td>

                <td class="pl-0 pt-8">
                  <span
                    class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                  >
                    {{ scopeCategory.name }}
                  </span>
                </td>

                <td class="pl-0 pt-8 text-center">
                  <a
                    class="btn btn-light-success font-weight-bolder font-size-sm"
                    @click="
                      showInputModal(
                        scopeCategory,
                        'Form Update Scope Category',
                        false
                      )
                    "
                  >
                    <i class="flaticon-edit"></i>
                  </a>

                  <a
                    class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                    @click="showDeleteModal(scopeCategory)"
                  >
                    <i class="flaticon-delete"></i>
                  </a>
                </td>
              </tr>
            <!-- </template> -->
          </template>
        </complete-table>
      </div>
    </div>

    <b-modal ref="input" hide-footer :title="modalTitle">
      <div class="d-block text-center">
        <form>
          <div class="row">
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Code *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Code"
                v-model="form.code"
                :disabled="!isCreate"
              ></b-form-input>
            </div>
            <div class="col-md-12 py-2 col-sm-12 text-left">
              <label for="input-large">Scope Category *</label>

              <b-form-input
                id="input-large"
                size="lg"
                placeholder="Scope Category Name"
                v-model="form.name"
              ></b-form-input>
            </div>
          </div>
        </form>

        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            block
            @click="isCreate ? saveScope() : updateScope()"
          >
            Save
          </b-button>

          <b-button
            :disabled="loading"
            class="btn btn-secondary mt-0"
            block
            @click="closeInputModal"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>

    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large"
              >Are You Sure Want To Delete Scope Category</label
            >
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="deleteScope"
            block
          >
            Delete
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import { arrayMoreThanOne } from "../../../core/helper/array-validation.helper";
import {
  DELETE_SCOPE_CATEGORY,
  GET_SCOPE_CATEGORY,
  SAVE_SCOPE_CATEGORY,
  UPDATE_SCOPE_CATEGORY,
} from "../../../core/services/store/scope-category.module";

export default {
  components: { CompleteTable },

  data() {
    return {
      filter: {
        code: null,
        name: null,
      },
      currentItem: null,
      modalTitle: null,
      form: {
        code: null,
        name: null,
      },
      isCreate: true,
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.scopeCategory.loading,
      scopeCategories: (state) => state.scopeCategory.scopeCategories?.data,
    }),

    filteredScopeCategories() {
      return this.scopeCategories;
    },
  },

  methods: {
    showInputModal(scopeCategory, modalTitle, isCreate = true) {
      this.currentItem = scopeCategory;

      this.form = {
        code: scopeCategory?.code,
        name: scopeCategory?.name,
      };

      this.modalTitle = modalTitle;

      this.isCreate = isCreate;

      this.$refs["input"].show();
    },

    showDeleteModal(scopeCategory) {
      this.currentItem = scopeCategory;

      this.$refs["delete"].show();
    },

    closeInputModal() {
      this.$refs["input"].hide();
    },

    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    getScopeCategory(filter = false) {
      if (!arrayMoreThanOne(this.scopeCategories) || filter) {
        this.$store.dispatch(GET_SCOPE_CATEGORY, {
          params: {
            ...this.filter,
          },
        });
      }
    },

    deleteScope() {
      this.$store
        .dispatch(DELETE_SCOPE_CATEGORY, {
          code: this.currentItem?.code,
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },

    saveScope() {
      this.$store.dispatch(SAVE_SCOPE_CATEGORY, this.form).then(() => {
        this.closeInputModal();
      });
    },

    updateScope() {
      this.$store.dispatch(UPDATE_SCOPE_CATEGORY, this.form).then(() => {
        this.closeInputModal();
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Kategori Scope" }]);

    this.getScopeCategory();
  },
};
</script>
